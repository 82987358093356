export default () => {
    // Get all "navbar-burger" elements
    const $navbarBurgers = [...document.getElementsByClassName('navbar-burger')]
    // Add a click event on each of them
    $navbarBurgers.forEach(el =>
        el.addEventListener('click', () => {

            // Get the target from the "data-target" attribute
            const target = el.dataset.target;
            const $target = document.getElementById(target);

            // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
            el.classList.toggle('is-active');
            $target.classList.toggle('is-active');
        })
    );
}